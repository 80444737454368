<app-TopMenu></app-TopMenu>

<router-outlet></router-outlet>

<!-- <ho1a-Inicio></ho1a-Inicio> -->


<div style="display: flex; justify-content: space-between; align-items: center; height: 80px; overflow: hidden;  margin-left: 10%; margin-right: 10%; width: 80%;">
    <div class="text-center copy">
<!--      Conoce nuestra Política del Sistema Integrado de Gestión-->
      Powered by Polígono Capital © 2024
    </div>
<!--    <a style="text-decoration: underline;"-->
<!--      class="text-center copy">-->
<!--      Ver política del sistema-->
<!--    </a>    <a style="text-decoration: underline;"-->
<!--      class="text-center copy">-->
<!--      Ver política del sistema-->
<!--    </a>-->
</div>



